.App{
  height: 600px;
}

.logo{
  width: 600px;
  height: 200px;
  margin: auto 25%;
}

.upload{
  margin: auto 25%;
  border: 2px dashed black;
  background-color: lightgrey;
  height: 400px;
  width: 600px;
  padding: 20px;
  text-align: center;
}

input{
  padding: 5px;
  margin: 3% 30%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
p{
  margin: 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
}

i{
  font-size: 80px;
  margin: 0px 45%;
}

.preview img{
  width: 120px;
  height: 150px;
}